.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .carouselWrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &:hover {
      .swipeLeft,
      .swipeRight {
        position: absolute;
        top: 45%;
        z-index: 1;
        display: block;
        padding: 8px 6px;
        border-radius: 10px;
      }
    }

    .swipeLeft,
    .swipeRight {
      display: none;
    }

    .swipeLeft {
      left: 0;
    }

    .swipeRight {
      right: 0;
    }

    .carousel {
      display: flex;
      width: 100%;
      height: 100%;
      gap: 17px;

      li {
        flex: none;
        object-fit: contain;
        height: 100%;
        width: 100%;
      }
      ul {
        width: 100%;
        height: 100%;
      }
    }
  }

  .carouselItem {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 350px;
    border-radius: 10px;
    overflow: hidden;
    transition: border 300ms;

    img {
      flex-shrink: 0;
      min-width: 100%;
      min-height: 100%;
    }
  }

  .carouselItem:hover {
    border-radius: 10px;
    border: 1px solid var(--color-border-level1, #363636);
  }

  .carouselItem section {
    width: 100%;
    border-radius: 10px;
    border: 1px solid #eee;
    padding: 24px;
  }

  .contentHeader {
    display: flex;
    gap: 8px;
    padding-bottom: 4px;
  }

  .contentHeader span {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 32px;
    border: 1px solid var(--color-bg-tag-green, #49c689);
    padding: 12px 8px;
    height: 30px;
    color: var(--color-bg-tag-green, #49c689);
    font-weight: 500;
  }

  .todayClosing {
    color: #b240f8 !important;
    border: 1px solid #b240f8 !important;
  }

  .title {
    padding-bottom: 12px;
    font-size: var(--font-size-3XLarge, 28px);
    font-weight: 500;
  }

  .title a,
  .title span {
    font-size: var(--font-size-3XLarge, 28px);
    font-weight: 500;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .contentName {
    font-weight: 600;
    font-size: var(--font-size-Regular, 16px);
  }

  .contentDetail {
    display: flex;
    gap: 8px;
  }

  .contentDetail p,
  .contentDetail div,
  .contentDetail span {
    font-size: var(--font-size-2XSmall, 12px);
  }

  .contentDetailCategory {
    color: #7d7d7d;
  }

  .not {
    color: var(--palette-common-gray100, #b240f8) !important;
  }

  .contentDetailCategory > :first-child,
  .contentDetailTxt > :first-child {
    padding-bottom: 8px;
  }

  .actionBtn {
    padding-top: 24px;
  }
}
.Paginator {
  display: flex;
  gap: 8px;
  justify-content: center;
  padding: 16px 0;
}
