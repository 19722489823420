.BoardWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  /* gap: 16px; */
}

.BoardItem {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--Space-2XSmall, 4px);
  flex: 1 0 0;
  padding: 8px;
  container-type: inline-size;
}

.BoardItem:hover {
  border-radius: 8px;
  background: var(--color-bg-hover, #f2f2f2);
}

.BoardInfo {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: var(--Space-2XSmall, 4px);
  color: var(--color-text-level3, #999);
}

.BoardInfo span {
  font-size: 12px;
}

.BoardInfo a {
  color: var(--color-text-level3, #999) !important;
  font-size: 12px;
}

.BoardTitle {
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
}

.BoardStickable {
  color: var(--palette-orange-orange50, #ff9b52) !important;
}

.BoardThumbnail {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@container (min-width: 251px) {
  .BoardThumbnail {
    flex-direction: row;
  }
}

.BoardThumbnailImg {
  margin-left: auto;
}

.BoardThumbnailImg img {
  width: 250px;
  height: 90px;
  border-radius: 12px;
}

.BoardAlbum {
  width: 100%;
  height: 110px;
  border-radius: 12px;
}

.BoardAlbum a {
  position: relative;
  display: inline-block;
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 12px;
}

.BoardAlbum:hover {
  background-color: var(--bg-transblack-40, rgba(0, 0, 0, 0.4));
}

.BoardAlbum:hover .OverlayTxt {
  display: block;
}
.BoardAlbum:hover .BoardAlbumImg {
  transition-duration: 0.3s;
  transform: scale(1.1);
  opacity: 0.4;
}

.BoardAlbumImg {
  width: 100%;
  height: 100%;
  transition-duration: 0.3s;
  background-size: cover;
  background-position: center;
  background-color: black;
}

.OverlayTxt {
  display: none;
  position: absolute;
  padding: 12px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  z-index: 1;
  transition: all 1000ms;
  -webkit-transition: all 1000ms;
}

.OverlayTxt > span {
  color: white;
}

.BoardAlbumDate,
.BoardAlbumName {
  position: absolute;
  left: 12px;
  font-size: 13px;
  font-weight: normal !important;
  color: #fff !important;
  width: 100%;
  padding-right: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
}

.BoardAlbumDate {
  top: 32px;
  opacity: 0.7;
}

.BoardAlbumName {
  bottom: 12px;
}
